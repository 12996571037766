@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caramel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Dirt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Distressed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Maze&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

@keyframes anchorHighlight {
    from {background-color: yellow;}
    to {background-color: transparent;}
}

html {
    margin: 0;
    height: 100dvh;
}

body {
    margin: 0;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100dvh;
}

.highlight {
    animation-name: anchorHighlight;
    animation-duration: 4s;
}

#doc-reader-legal {
    font-size: 10pt;
    font-family: arial;
    background-color: #FFFFFF;
    text-align: justify;
}

#doc-reader-legal td {
    font-size: 10pt;
    font-family: arial;
    text-align: justify;
}

#doc-reader-legal p {
    margin: 0cm;
}

#doc-reader-legal span {
    text-align: justify;
}

#doc-reader-legal div {
    text-align: justify;
}

#doc-reader-legal a:link {
    color: #0000FF;
}

#doc-reader-legal a:hover {
    color: #0000FF;
}

#doc-reader-legal a:visited {
    color: #0000FF;
}

#doc-reader-legal div.Valid {
    font-size: 10pt;
    font-weight: bold;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 30px;
}

#doc-reader-legal div.Name {
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
}

#doc-reader-legal div.NameExt {
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
}

#doc-reader-legal div.Nadpis1 {
    margin-top: 10pt;
    margin-bottom: 10pt;
    font-size: 13pt;
    font-weight: bold;
    text-align: center;
}

#doc-reader-legal div.Nadpis2 {
    margin-top: 10pt;
    margin-bottom: 5pt;
    font-size: 12pt;
    font-weight: bold;
    text-align: center;
}

#doc-reader-legal div.Nadpis2left {
    margin-top: 10pt;
    margin-bottom: 5pt;
    font-size: 11pt;
    font-weight: bold;
    text-align: left;
}

#doc-reader-legal div.Nadpis3 {
    margin-top: 5pt;
    margin-bottom: 5pt;
    font-size: 11pt;
    font-weight: bold;
    text-align: left;
}

#doc-reader-legal div.Nadpis4 {
    margin-top: 5pt;
    font-size: 10pt;
    font-weight: bold;
    text-align: left;
}

#doc-reader-legal div.Nadpis5 {
    margin-top: 2pt;
    font-size: 10pt;
    font-weight: normal;
    text-align: left;
}

#doc-reader-legal div.Paragraf {
    margin-top: 10pt;
    margin-bottom: 10pt;
}

#doc-reader-legal div.Odsek {
    margin-left: 14pt;
    text-indent: -14pt;
}

#doc-reader-legal div.Pismeno {
    margin-left: 10pt;
    text-indent: -10pt;
}

#doc-reader-legal div.ZoznamPolozka {
    margin-left: 14pt;
    margin-bottom: 10pt;
    text-indent: -14pt;
}

#doc-reader-legal div.Zoznam {
    margin-top: 10pt;
    margin-bottom: 10pt;
    text-indent: -14pt;
}

#doc-reader-legal ul.Zoznam2 {
    font-size: 10pt;
    list-style: inside;
}

#doc-reader-legal div.Celok {
    margin-top: 10pt;
    margin-bottom: 50pt;
    text-align: justify;
}

#doc-reader-legal div.Poznamka {
    font-size: 8.5pt;
    margin-left: 10pt;
    text-indent: -10pt;
}

#doc-reader-legal div.change {
    font-weight: bold;
}

#doc-reader-legal div.changes {
    margin-top: 5pt;
    margin-bottom: 5pt;
}

#doc-reader-legal table {
    margin-top: 8pt;
    margin-bottom: 8pt;
}

#doc-reader-legal .ElementOverlayX {
    background-color: #cddc39;
    opacity: 0.4; /* transparency */
    filter: alpha(opacity=60); /* IE transparency */
}

#doc-reader-legal .displayNone {
    display: none;
}

#doc-reader-legal div.Content {
    page-break-after: always;
}

#doc-reader-legal div.Content ul {
    list-style-type: none;
}
#doc-reader-legal span.del {
    background-color: red;
}

#doc-reader-legal .del span {
    background-color: red;
}

#doc-reader-legal .del td {
    background-color: red;
}

#doc-reader-legal span.new {
    background-color: lime;
}

#doc-reader-legal .new span {
    background-color: lime;
}

#doc-reader-legal .new td {
    background-color: lime;
}

#doc-reader-legal span.sel {
    background-color: blue;
    color: white;
}

#doc-reader-legal .sel span {
    background-color: blue;
    color: white;
}
